import React, { FC } from 'react';
import {
  SolutionsWhiteLabelHero,
  SolutionsWhiteLabelFeatures,
} from '../../components/solutions/white-label';
import { PageLayout } from '../../components/layout';
import { SolutionsMakeAnAppointment } from '../../components/solutions';

const Page: FC = () => {
  /**
   * DOM
   */
  return (
    <PageLayout bgCls="bg-accent-light">
      <SolutionsWhiteLabelHero />
      <SolutionsWhiteLabelFeatures />
      <SolutionsMakeAnAppointment cls="light" icon="email-checked" />
    </PageLayout>
  );
};

export default Page;
